ion-toast.hchat-toast {
  --background: #f9fafc;
  --border-color: #e6eaeb;
  --border-style: solid;
  --border-width: 1px;
  --border-radius: 10px;
  --box-shadow: unset;
  --color: #000;

  &::part(container) {
    height: 40px;
  }
}

ion-modal {
  &.pixel-modal-auto-height {
    --height: auto;
    --max-height: 90vh;
  }
}

ion-modal {
  &.pixel-modal-fullscreen {
    --border-radius: 0;
    --height: 100%;
    --max-height: 100%;
    --width: 100%;
    --max-width: 100%;

    .modal-wrapper {
      height: 100%;
      max-height: 100%;
      width: 100%;
      max-width: 100%;
    }
  }
}
