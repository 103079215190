/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@import "../../../libs/client/src/global-spinner/styles/global-spinner-v2";
/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

// font
@import 'pretendard/dist/web/static/pretendard.css';

// ag grid
@import "@ag-grid-community/styles/ag-grid.css";
@import "@ag-grid-community/styles/ag-theme-alpine.css";

// angular material
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'material-design-icons-iconfont/dist/material-design-icons.css';

// flynnlab
@import "../../../libs/client/src/scss/utils-mixin";
@import '../../../libs/client/src/scss/flynnlab-style';
@import '../../../libs/client/src/scss/custom-ionic-style';
@import '../../../libs/client/src/scss/insta-chat-material';
@import 'animate.css';

// datetime picker - for admin
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

/* Set variables for all modes */
:root {
  /* Set the background of the entire app */
  //--ion-background-color: #ff3700;

  /* Set the font family of the entire app */
  --ion-font-family: Pretendard, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Roboto', sans-serif;
  --mdc-typography-font-family: var(--ion-font-family);
  --mat-form-field-container-text-font: var(--ion-font-family);
}

body {
  background-color: var(--ion-background-color);
  color: var(--ion-text-color);
}

.plt-desktop {
  @extend %outer-scrollbar;
}
