@mixin outer-scrollbar($bg, $bw: 3px) {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    background: $bg;
    border-radius: 100px;
    background-clip: padding-box;
    border: $bw solid transparent;
  }

  &::-webkit-scrollbar-corner {
    background-color: rgba(0, 0, 0, 0);
  }
}

%outer-scrollbar {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    height: 6px;
    border: 1px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 7px;
    box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0), inset 1px 1px 0 rgba(0, 0, 0, 0);
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05), inset 1px 1px 0 rgba(0, 0, 0, 0.05);
    }
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  ::-webkit-scrollbar-button {
    display: none;
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

@mixin filterBackground($color:#000000, $opacity:0.5) {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color, $opacity);
  }
}
