@use "flex-layout-sass/mixin" as f;
@import "./insta-chat-variables";

:root {
  --main-primary-color: #4b5cef;
}

$input-placeholder-color: #E0E0E0;
$input-color: #1A1A1A;
$label-color: #6B7379;
$border-color: #E6E6E6;
$icon-button-color: #A6A9AF;
$checkbox-border-color: #E6E6E6;


mat-checkbox.insta-chat-checkbox {
  .mdc-checkbox {
    --mdc-checkbox-unselected-icon-color: var(--main-primary-color);
    --mdc-checkbox-selected-icon-color: var(--main-primary-color);
    --mdc-checkbox-selected-focus-icon-color: var(--main-primary-color);
    --mdc-checkbox-selected-hover-icon-color: var(--main-primary-color);
    --mdc-checkbox-selected-pressed-icon-color: var(--main-primary-color);
    --mdc-checkbox-unselected-focus-icon-color: var(--main-primary-color);
    --mdc-checkbox-unselected-hover-icon-color: var(--main-primary-color);

    .mdc-checkbox__ripple {
      display: none;
      opacity: 0;
      visibility: hidden;
    }

    .mdc-checkbox__background {
      border-radius: 6px;
      width: 28px;
      height: 28px;
      border-width: 1px;
      top: calc((var(--mdc-checkbox-state-layer-size) - 29px) / 2);
      left: calc((var(--mdc-checkbox-state-layer-size) - 29px) / 2);

      .mdc-checkbox__checkmark {
        width: 15px;
        margin: 5px 0 0 6px;
      }
    }

  }

  .mdc-form-field {
    width: 100%;
  }

  .mdc-label {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.015em;
    text-align: left;
    padding-left: 0;

    width: 100%;

    .primary {
      color: var(--main-primary-color);
    }

    .default {
      color: #a7a9af;
    }

    .label {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.015em;
      color: #1A1A1A;
    }

    .label-description {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.02em;
      color: #6B7379;
    }
  }

  &.has-description {
    .mdc-label {
      padding: 5px 10px;
    }
  }
}

mat-radio-group.insta-chat-radio-group, .insta-chat-checkbox-group {
  @include f.fx-layout(column, center start, 5px);
}

mat-radio-group.insta-chat-radio-group.v2 {
  @include f.fx-layout(column, center start, 8px);

  mat-radio-button.insta-chat-radio-button.mat-mdc-radio-button {
    &.mat-mdc-radio-checked {
      .mdc-form-field {
        border: 1px solid var(--main-primary-color);
      }
    }

    .mdc-form-field {
      border: 1px solid #E6E6E6;
      border-radius: 10px;
      padding: 7px 5px;
    }
  }
}

.insta-chat-checkbox-group {
  mat-checkbox.insta-chat-checkbox {
    width: 100%;
  }
}

mat-radio-button.insta-chat-radio-button.mat-mdc-radio-button {
  width: 100%;

  .mdc-radio {
    .mat-radio-ripple {
      display: none !important;
      visibility: hidden;
      opacity: 0;
    }

    .mdc-radio__background {
      --mdc-radio-unselected-icon-color: #{$checkbox-border-color};
      --mdc-radio-unselected-hover-icon-color: var(--main-primary-color);
      --mdc-radio-selected-icon-color: var(--main-primary-color);
      --mdc-radio-selected-hover-icon-color: var(--main-primary-color);
      --mdc-radio-selected-focus-icon-color: var(--main-primary-color);
      --mdc-radio-unselected-pressed-icon-color: var(--main-primary-color);
      --mdc-radio-unselected-focus-icon-color: var(--main-primary-color);
      --mdc-radio-selected-pressed-icon-color: var(--main-primary-color);
      width: 28px;
      height: 28px;
      top: -4px;
      left: -4px;

      .mdc-radio__outer-circle {
        border-width: 1px;
      }

      .mdc-radio__inner-circle {
      }

      &::before {
        opacity: 0;
        background: transparent;
        top: calc(-1 * (var(--mdc-radio-state-layer-size) - 34px));
        left: calc(-1 * (var(--mdc-radio-state-layer-size) - 34px));
      }
    }

    .mdc-radio__native-control {

    }

    .mdc-radio__native-control:checked + .mdc-radio__background {
      .mdc-radio__outer-circle {
        --mdc-radio-selected-icon-color: #{$checkbox-border-color};
      }

      .mdc-radio__inner-circle {
        border-width: 14px;
        transform: scale(0.6);
      }
    }
  }

  .mdc-form-field {
    width: 100%;

    & > label {
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: -0.015em;
      text-align: left;
      color: #1A1A1A;
      width: 100%;
    }
  }
}

.insta-chat-button {
  --button-disabled-background-color: #D1D2D7;
  --button-primary-background-color: var(--main-primary-color);
  --button-primary-border-color: var(--main-primary-color);
  --button-primary-font-color: #FFFFFF;

  --button-danger-background-color: #FFFFF;
  --button-danger-border-color: #D1D2D7;
  --button-danger-font-color: #{$danger-color};

  --button-default-background-color: #FFFFFF;
  --button-default-border-color: #E6E6E6;
  --button-default-font-color: #{$default-color};

  --button-black-background-color: #FFFFFF;
  --button-black-border-color: #E6E6E6;
  --button-black-font-color: #{$black-color};
  --button-height: 51px;
  background-color: var(--button-default-background-color);
  border: 1px solid var(--button-default-border-color);
  color: var(--button-default-font-color);
  height: var(--button-height);
  min-height: var(--button-height);
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  padding: 0 20px;
  min-width: 58px;

  @include f.fx-layout(row, center center);

  &:focus-visible {
    outline: var(--main-primary-color) solid 2px;
  }


  // 빌드 타임에 안되어서 어쩔수 없음 (스토리북에서만 일수도 있음)
  ion-icon {
    margin: 0 7px;
  }

  &.big {
    --button-height: 56px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.015em;
    text-align: left;
    padding: 0 5px;
  }

  &.small {
    --button-height: 34px;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.02em;
    text-align: left;
    padding: 0 0 0 11px;
    color: #1A1A1A;
    width: auto;
    border-radius: 5px;

    & ion-icon {
      color: #A6A9AF;
    }
  }

  &.toggle-mode {
    --button-default-font-color: #1A1A1A;
    border: 1px solid #E6E6E6;
    border-radius: 50px;
    padding: 0 11px;
    font-weight: 500;

    &.selected {
      font-weight: 600;
      border-color: #000;
    }
  }

  &.primary {
    background-color: var(--button-primary-background-color);
    border-color: var(--button-primary-border-color);
    color: var(--button-primary-font-color);

    .label {
      color: #9BA5FF;
    }

    .value {
      margin-left: 4px;
    }
  }

  &.black {
    background-color: var(--button-black-background-color);
    border-color: var(--button-black-border-color);
    color: var(--button-black-font-color);
  }

  &.default {
    background-color: var(--button-default-background-color);
    border-color: var(--button-default-border-color);
    color: var(--button-default-font-color);
  }

  &.danger {
    background-color: var(--button-danger-background-color);
    border-color: var(--button-danger-border-color);
    color: var(--button-danger-font-color);
  }

  &:disabled {
    background-color: var(--button-disabled-background-color);
    border-color: var(--button-disabled-background-color);
  }

  &.disabled-style {
    background-color: var(--button-disabled-background-color);
    border-color: var(--button-disabled-background-color);
  }
}

.insta-chat-float-button {
  position: absolute;
  bottom: calc(var(--offset-bottom, 0px) + 20px);
  right: 20px;
  width: max-content;
  border-radius: 30px;
  background-color: #000000;
  color: #ffffff;
  border-color: #000;
  box-shadow: rgba(88, 88, 88, 1) 0 0 20px 0;
}

:root {
  --insta-chat-icon-size: 21px;
  --insta-chat-color: #000000;
  --insta-chat-icon-background: #EFEFEF;
}

.insta-chat-icon-button {
  color: var(--insta-chat-color);
  width: 36px;
  height: 36px;
  font-size: var(--insta-chat-icon-size, 21px);
  background: transparent;
  border-radius: 10px;
  cursor: pointer;
  @include f.fx-layout(row, center center);

  &.has-background {
    background: var(--insta-chat-icon-background);
  }
}

ion-back-button.insta-chat-back-button {
  min-width: 32px;
  height: 36px;
  --padding-start: 6px;
  --padding-end: 6px;
  --padding-top: 6px;
  --padding-bottom: 6px;
  margin-right: 4px;
}

.cdk-overlay-container {
  z-index: 20001;
}

mat-snack-bar-container.mat-mdc-snack-bar-container.hchat-toast {
  --mdc-snackbar-container-color: #1A1A1A;
  --mdc-snackbar-supporting-text-font: Pretendard, Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 18px;
  --mdc-snackbar-supporting-text-size: 15px;
  --mdc-snackbar-supporting-text-weight: 500;

  --mdc-snackbar-container-shape: 5px;

  .mdc-snackbar__surface {
    min-width: unset;
    margin-bottom: 50px;

    .mdc-snackbar__label {
      letter-spacing: -0.02em;
      padding: 8px 4px 8px 12px;
    }
  }
}

.pixel-confirm-dialog {
  .mat-mdc-dialog-container {
    --mdc-dialog-container-shape: 15px;

  }
}

.insta-chat-input {
  &:focus-visible {
    outline: var(--main-primary-color) solid 2px;
  }
}

ion-toggle {
  --track-background-checked: var(--main-primary-color);
}

ion-modal {
  --border-radius: 20px;
}

.action-sheet-button {
  text-transform: capitalize;
}

ion-ripple-effect {
  color: #d9d9d9;
}

.chip-button {
  width: fit-content;
  border-radius: 19px;
  background: #FFFFFF;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #E6E6E6;
  color: #000000;
  padding: 2px 12px 2px 12px;
  height: 40px;
  @include f.fx-layout(row, center center);

  &.selected {
    color: #FFF;
    border-color: var(--main-primary-color);
    background: var(--main-primary-color);
  }
}

.board-post {
  --textarea-border-color: transparent;
  --textarea-placeholder-color: #{$input-placeholder-color};
  --mdc-filled-text-field-active-indicator-color: transparent;
  --mdc-filled-text-field-hover-active-indicator-color: transparent;
  --mdc-filled-text-field-focus-active-indicator-color: transparent;
  --mdc-filled-text-field-error-active-indicator-color: transparent;
  --mdc-filled-text-field-error-focus-active-indicator-color: transparent;
  --mdc-filled-text-field-error-hover-active-indicator-color: transparent;
  --count-hint-color: #A6A9AF;
  --textarea-color: #1A1A1A;

  width: 100%;

  .mdc-text-field__input {
    --mat-form-field-container-text-size: 20px;
  }

  &.mdc-text-field .mdc-text-field__input {
    //font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.015em;
    text-align: left;
    color: var(--textarea-color);
  }

  &.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.015em;
    text-align: left;
    color: var(--textarea-color);

    &::placeholder {
      color: var(--textarea-placeholder-color);
    }
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }

  .mdc-line-ripple {
    &::before {
      border-bottom-width: 0;
    }

    &::after {
      border-bottom-width: 0;
    }
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) {
    .mat-mdc-form-field-infix {
      padding-top: 15px;
      padding-bottom: 8px;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mdc-text-field {
    padding: 0 12px;
  }
}

.error-message {
  margin-top: 5px;
  color: var(--mdc-outlined-text-field-error-caret-color);
  font-size: 13px;
}

.ion-content-scroll-host::before,
.ion-content-scroll-host::after {
  position: absolute;

  width: 1px;
  height: 1px;

  content: "";
}

.ion-content-scroll-host::before {
  bottom: -1px;
}

.ion-content-scroll-host::after {
  top: -1px;
}

.multiline-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--multiline-maxlines, 2); /* 표시할 줄 수 */
  overflow: hidden;
  text-overflow: ellipsis;
  /* 필수는 아니지만, 필요할 경우 */
  line-height: 1.3;
  max-height: calc(1.5em * var(--multiline-maxlines, 2)); /* line-height * 줄 수 */
}

.fixed-font-variant {
  font-variant-numeric: tabular-nums;
}

.debug-area {
  pointer-events: none;
  padding: 1px 4px;
  border-radius: 3px;
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 12px;
}

.capitalize {
  text-transform: capitalize;
}
