a {
  text-decoration: none;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

/* Chrome, Safari, Edge 등 일부 브라우저에서 스핀 버튼 숨기기 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox에서 스핀 버튼 숨기기 */
input[type="number"] {
  -moz-appearance: textfield;
}

.pixels-storybook {
  width: 420px;
  height: calc(100vh - 100px);
  background: #00a0e9;
  padding: 10px;
  box-sizing: border-box;
}
